export default {
    name: 'travelPolicyTips',
    data () {
        return {}
    },
    props: {
        myTravelPolicy: {
            type: Object,
            default: () => {},
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {},
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {}
}
